/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import {  Box } from 'theme-ui';
import { Link } from 'gatsby';
import slugify from 'slugify'

import Left from '../components/icons/Left';

const EventsHeaderImg = ({ image, name }) => {
  const BackBtn = () => {
    return (
      <Link
        to={`/places/${slugify(name, {lower: true})}`}
        sx={{
          textDecoration: 'none',
          display: 'inline-flex',
          alignItems: 'center',
          color: '#fff',
          backgroundColor: 'rgba(0,0,0,.6)',
          paddingX: 3,
          paddingY: 1,
          cursor: 'pointer',
          fontWeight: '500',
          '&:hover': {
            background: 'rgb(0,0,0)',
          },
        }}>
        <Left fill='#fff' /> Back to {name}
      </Link>
    );
  };
  return (
    <Box
      sx={{
        background: `url(${image.replace(/650/g, '2000')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: ['500px', '700px'],
      }}>
      <BackBtn />
    </Box>
    // <>
    //     <Image src={image}></Image>
    // </>
  );
};

export default EventsHeaderImg;
