/** @jsx jsx */
import React, {useState} from 'react';
import { jsx } from 'theme-ui';
import { Text, Box, Flex, Image } from 'theme-ui';
import dayjs from 'dayjs';
import Lightbox from 'react-image-lightbox'


import formatEventDays from '../helpers/formatEventDays';
import cloudinaryHelper from '../helpers/cloudinaryHelper'

const EventsContent = ({ event }) => {
    const [lightbox, setLightbox] = useState({
      photoIndex: 0,
      isOpen: false,
    });

  const {
    name,
    startAt,
    endAt,
    image,
    description,
    eventDays,
    eventTags,
  } = event;

  const displayEventDate = () => {
    if (eventDays) {
      return (
        <>
          <Text>Every {formatEventDays(Object.keys(eventDays))}</Text>
        </>
      );
    } else {
      return (
        <>
          <Text>{dayjs(startAt).format('dddd, MMMM D')}</Text>
        </>
      );
    }
  };

  const renderTags = () =>
    eventTags.map(tag => (
      <Text as='span' sx={{ fontSize: 0, borderRadius: '30px', marginRight: 2, marginTop: 2, paddingX: 3, paddingY: 2, background: '#EBEBEB' }}>
        {tag}
      </Text>
    ));

  return (
    <Box
      sx={{
        padding: [2, 2, 3],
        borderRadius: '13px',
        mt: [-4, -4, -6],
        backgroundColor: '#fff',
        zIndex: 2,
        position: 'relative',
        boxShadow: '0 0 12px rgba(0,0,0,.1)',
        maxWidth: '900px', 
        marginX: 'auto'
      }}>
      {lightbox.isOpen && (
        <Lightbox
          mainSrc={cloudinaryHelper(image, 1800)}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        />
      )}
      <Text
        as='h1'
        sx={{
          fontSize: [4, 4, 5],
          mb: 2,
        }}>
        {name}
      </Text>
      <Text
        as='h4'
        sx={{
          fontSize: 3,
          fontWeight: '500',
          mb: 3,
        }}>
            An event by {event.business.name}
        </Text>
      <Text as='p' sx={{ fontSize: 2, fontWeight: 600, mb: 2 }}>
        When:{' '}
      </Text>
      {displayEventDate()}
      <Text
        as='p'
        sx={{ lineHeight: 2, pt: 4, mt: 4, borderTop: '1px solid #eee' }}>
        {description}
      </Text>
      {image ? <Box sx={{ my: 4, textAlign: 'center' }}>
        <Image onClick={() => setLightbox({ isOpen: true })} src={image} sx={{cursor: 'pointer'}} />
      </Box> : ''}
      
      <Flex sx={{ flexWrap: 'wrap', mt: 3, justifyContent: 'center' }}>{renderTags()}</Flex>
    </Box>
  );
};

export default EventsContent;
