/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { Box } from 'theme-ui';

import Layout from '../components/Layout'
import EventsHeaderImg from '../components/EventsHeaderImg'
import EventsContent from '../components/EventsContent';

const eventsPageTemplate = ({ pageContext}) => {
    const {data, events} = pageContext
    const event = events.gonationObject    
    const image = event.image || data.cover

    return (
      <Layout pageTitle={`${event.name} at ${data.name}`}>
        <EventsHeaderImg image={image} name={data.name} />
        <Box sx={{ paddingX: 3, paddingY: 4, marginTop: '-2rem' }}>
          <EventsContent event={event} />
        </Box>
      </Layout>
    );
}

export default eventsPageTemplate;
